

.wrapper.image-wrapper {
  /* background-image: url('../public/assets/img/cover.png'); */
  background-size: cover;
}
.image-wrapper.bg-overlay-300:before {
  background: rgba(30,34,40,.3);
}
.image-wrapper.bg-overlay:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(30,34,40,.5);
}

.btn-primary {
  background-color: #fa9e19 !important;
  border-color: #fa9e19 !important;
}

.icon-list.bullet-soft-red i {
  color: #fa9e19;
}

.text-primary {
  --bs-text-opacity: 1;
  color: #fa9e19!important;
}



